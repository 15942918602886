import React, { useState } from "react"
import styled from "styled-components"
import { Check } from "styled-icons/material/Check"

import globalVariables from "../../globalVariables"

const Button = styled.button`
  background: ${props =>
    props.isActive ? props.theme.greyLighter : "transparent"};
  border: 2px solid ${props => props.theme.greyLighter};
  color: ${props => props.theme[props.color]};
  padding: 10px 20px;
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: ${props => (props.isActive ? "bold" : "normal")};
  font-size: 18px;
  cursor: pointer;
  min-width: 130px;
  &:hover {
    font-weight: bold;
  }
`

const Content = styled.div`
  background: ${props => props.theme.greyLighter};
  padding: 20px;
  ul {
    margin-left: 0;
    @media (min-width: ${globalVariables.minTablet}) {
      margin-left: 1rem;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }
  span {
    color: ${props => props.theme.bodyColor};
    list-style: none;
  }
`
const Wrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
`
const CheckStyled = styled(Check)`
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  color: ${props =>
    props.location === "nantes" ? props.theme.primary : props.theme.secondary};
`

const Tabs = ({ coachingDistance, coachingNantes }) => {
  const [location, setLocation] = useState("nantes")
  const handleClick = value => {
    setLocation(value)
  }

  const items = location === "nantes" ? coachingNantes : coachingDistance

  return (
    <React.Fragment>
      <Button
        onClick={() => handleClick("nantes")}
        color="primary"
        isActive={location === "nantes"}
      >
        À Nantes
      </Button>
      <Button
        onClick={() => handleClick("distance")}
        color="secondary"
        isActive={location === "distance"}
      >
        À Distance
      </Button>
      <Content>
        <ul>
          {items.map((item, index) => (
            <Wrapper key={index}>
              <CheckStyled location={location} />
              <span>{item.contenu.text}</span>
            </Wrapper>
          ))}
        </ul>
      </Content>
    </React.Fragment>
  )
}

export default Tabs
