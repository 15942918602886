import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Commun/Banner"
import Intro from "../components/Coaching/Intro"
import Gallery from "../components/Commun/Gallery"
import Social from "../components/Commun/Social"
import Temoignages from "../components/Commun/Temoignages/TemoignageNoImage"
import Contact from "../components/Commun/Contact"

const APropos = ({ data }) => {
  const prismicData = getPrismicData(data)

  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO
        title="A Propos | Kevin Jehanno | Coach Sportif"
        description="J'ai enseigné ma méthode de coaching sur près d’une centaine d’hommes et de femmes à Nantes et à distance sur des objectifs variés tels que : la perte de poids, la réathlétisation ou la prise de masse."
      />
      <Banner
        title={prismicData.banner.titre}
        imageBanner={prismicData.banner.image}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
        objectPosition="center 20% !important"
      />
      <Intro
        titre={prismicData.contenuPrincipal.titre}
        text={prismicData.contenuPrincipal.contenu}
        text2={prismicData.contenuPrincipal.text2}
      />
      <Gallery />
      <Social />
      <Temoignages items={prismicData.temoignage.items} />
      <Banner
        title={prismicData.accroche.contenu}
        imageBanner={data.accroche.childImageSharp.fluid}
        imageHeight="450px"
        imageMobileHeight="350px"
        buttonText="Voir mes coachings"
        buttonUrl="/coaching-sportif-personnalise"
        colorsButton="primaryWithBg"
      />
      <Contact />
    </Layout>
  )
}

export default APropos

export const query = graphql`
  query {
    prismicAProposBodyBanniere {
      primary {
        contenu {
          html
          text
        }
        image {
          fluid(maxWidth: 3000) {
                ...GatsbyPrismicImageFluid
              }
        }
      }
    }
    prismicAProposBodyContenuPrincipal {
      primary {
        titre {
          text
        }
        contenu {
          html
        }
        contenu_colonne_2 {
          html
        }
      }
    }
    prismicAccueilBodyAccroche {
      primary {
        contenu {
          html
          text
        }
      }
    }
    prismicAProposBodyTemoignages {
      items {
        contenu {
          html
          text
        }
        nom {
          html
          text
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    accroche: file(relativePath: { eq: "accroche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicAProposBodyBanniere.primary.contenu.text,
      image:
        data.prismicAProposBodyBanniere.primary.image.fluid,
    },
    contenuPrincipal: {
      titre: data.prismicAProposBodyContenuPrincipal.primary.titre.text,
      contenu: data.prismicAProposBodyContenuPrincipal.primary.contenu.html,
      text2:
        data.prismicAProposBodyContenuPrincipal.primary.contenu_colonne_2.html,
    },
    accroche: {
      contenu: data.prismicAccueilBodyAccroche.primary.contenu.text,
    },
    temoignage: {
      items: data.prismicAProposBodyTemoignages.items,
    },
  }
}
