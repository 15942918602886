import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import globalVariables from "../../globalVariables"
import Tabs from "../../Commun/Tabs"

const Title = styled.h2`
  color: ${props => props.theme.headerColor};
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 1.5rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Item = styled.div`
  flex: 1 1 100%;
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 1 48%;
  }
  p {
    color: ${props => props.theme.bodyColor};
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
`

const WrapperTabs = styled.div`
  margin-top: 3rem;
`

const Intro = ({ titre, text, text2, coachingDistance, coachingNantes }) => {
  return (
    <Section>
      <Container>
        <Title>{titre}</Title>
        <Row>
          <Item>{Parser(text)}</Item>
          {text2 && <Item>{Parser(text2)}</Item>}
        </Row>
        {coachingDistance && coachingNantes && (
          <WrapperTabs>
            <Tabs
              coachingDistance={coachingDistance}
              coachingNantes={coachingNantes}
            />
          </WrapperTabs>
        )}
      </Container>
    </Section>
  )
}

export default Intro
