import React from "react"
import styled from "styled-components"
import { Youtube } from "styled-icons/fa-brands/Youtube"

import Section from "../../elements/Section"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import Newsletter from "../Newsletter"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const Wrapper = styled.div`
  flex: 0 0 50%;
  background-color: ${props => props.theme[props.color]};
  /* height: 300px; */
  padding: 80px 50px;
  text-align: center;
`

const Title = styled.p`
  color: ${props => props.theme.white};
  font-family: Rajdhani;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
`

const Text = styled.p`
  color: ${props => props.theme.white};
  max-width: 410px;
  margin: 1rem auto 2rem;
  min-height: 55px;
`

const WrapperButton = styled.div`
  min-height: 40px;
`

const YoutubeStyled = styled(Youtube)`
  color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

const Social = () => {
  return (
    <Section padding="0">
      <Container>
        <Wrapper color="ternary">
          <Title>Newsletter</Title>
          <Text>
            Inscris-toi à ma newsletter afin de recevoir des promotions et des
            conseils gratuits
          </Text>
          <WrapperButton>
            <Newsletter icon />
          </WrapperButton>
        </Wrapper>
        <Wrapper color="ternaryDark">
          <Title>E-books</Title>
          <Text>
          Découvre mes e-books de coaching sportif pour homme et pour femme 
          </Text>
          <WrapperButton>
            <Button
              title="Découvrir"
              as="link"
              to="/e-books"
              colors="primaryWithBg"
              iconPosition="left"
            />
          </WrapperButton>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default Social
