import React, { useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import axios from "axios"

// import Section from "../../elements/Section"
import globalVariables from "../../globalVariables"

import Picture1 from "../../../images/footer/insta1.jpeg"
import Picture2 from "../../../images/footer/insta2.jpg"
import Picture3 from "../../../images/footer/insta3.jpg"
import Picture4 from "../../../images/footer/insta4.jpg"
import Picture5 from "../../../images/footer/insta5.jpg"
import Picture6 from "../../../images/footer/insta6.jpg"
import Picture7 from "../../../images/footer/insta7.jpg"
import Picture8 from "../../../images/footer/insta8.jpg"

const Section = styled.section`
  margin-bottom: -10px;
  .slick-prev {
    left: 20px;
    z-index: 1;
  }
  .slick-next {
    right: 20px;
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${props => props.theme.primary};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0;
    margin-bottom: -10px;
    .slick-prev,
    .slick-next {
      z-index: -1;
    }
  }
`
const ImageWrapper = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    width: 350px !important;
  }
`

const Gallery = () => {
  // const [instaPhotos, setInstaPhotos] = useState()

  // // It will contain our photos' links
  // if (!instaPhotos) {
  //   const res = []

  //   axios.get("https://www.instagram.com/kevcoachingpro/").then(result => {
  //     const jsonObject = result.data
  //       .match(
  //         /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
  //       )[1]
  //       .slice(0, -1)
  //     const userInfo = JSON.parse(jsonObject)
  //     // Retrieve only the first 10 results
  //     const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(
  //       0,
  //       8
  //     )
  //     for (let media of mediaArray) {
  //       const node = media.node

  //       // Process only if is an image
  //       if (node.__typename && node.__typename !== "GraphImage") {
  //         continue
  //       }

  //       // Push the thumbnail src in the array
  //       res.push(node.thumbnail_src)
  //     }
  //     setInstaPhotos(res)
  //     return res
  //   })
  // }

  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
          className: "",
        },
      },
    ],
  }

  return (
    <Section>
      <Slider {...settings}>
        {/* {instaPhotos &&
          instaPhotos.map((image, index) => (
            <ImageWrapper key={index}>
              <img alt={`instagram Kevin Jehanno - ${index}`} src={image} />
            </ImageWrapper>
          ))} */}

        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture1} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture2} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture3} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture4} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture5} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture6} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture7} />
        </ImageWrapper>
        <ImageWrapper>
          <img alt={`instagram Kevin Jehanno `} src={Picture8} />
        </ImageWrapper>
      </Slider>
    </Section>
  )
}

export default Gallery
